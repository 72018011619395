import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
// D:\BIHARSPORTS\bssa-school-games-frontend\src\pages\dashboard\BatteryTest\Table.tsx
import Table from "../../../dashboard/BatteryTest/Table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ExportAsExcel } from "react-export-table"; // Import ExportAsExcel compon
import { records as data } from "../../../tables/data";

const columns = [
  {
    Header: "Sr. No",
    accessor: (row: any, index: number) => index + 1,
    sort: true,
  },
  {
    Header: "Player ID",
    accessor: "studentId",
    sort: true,
  },

  {
    Header: "Udise Code",
    accessor: "Udise_Code",
    sort: true,
  },
  // 
  {
    Header: "Name",
    accessor: "studentName",
    sort: true,
  },
  {
    Header: "Sports",
    // accessor: "gameName",
    accessor: (row:any)=> row.gameName?.map((gameObj:any) => gameObj.game).join(", ") || "", // Map over the array and join game names with commas
    sort: false,
  },
  {
    Header: "Age Category",
    accessor: "ageCategory",
    sort: true,
  },
];

// const sizePerPageList = [
//   { text: "5", value: 5 },
//   { text: "10", value: 10 },
//   { text: "25", value: 25 },
//   { text: "All", value: data.length },
// ];

const sizePerPageOptions = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "All", value: data.length }, // `data.length` assumes `data` is defined in this scope
];

interface ListProps {
  players: any[];
}

const List: React.FC<ListProps> = ({ players}) => {
  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  const schoolName = user?.School_Name || "School";
  const Udise_Code = user?.Udise_Code|| "Udise Code"

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [columns.map((col) => col.Header)],
      body: players.map((row, index) =>
        columns.map((col) => {
          if (typeof col.accessor === "function") {
            return col.accessor(row, index);
          } else {
            return row[col.accessor];
          }
        })
      ),
      didDrawPage: (data: { settings: { margin: { left: number } } }) => {
        doc.setFontSize(12);
        doc.setTextColor(40);
        doc.text(schoolName + " Players", data.settings.margin.left, 10);
      },
      styles: {
        fontSize: 10,
        fontStyle: "normal",
      },
      columnStyles: {
        0: { fontStyle: "bold" },
      },
    });
    doc.save(`${schoolName}_players.pdf`);
  };

  const exportToExcel = () => {
    // Example of data transformation if needed
    const dataForExcel = players.map((player) => ({
      Name: player.studentName,
      "Student ID": player.studentId,
      Sports: player.gameName,
      "Age Category": player.ageCategory,
    }));

    // Correct usage of ExportAsExcel component
    return (
      <ExportAsExcel
        data={dataForExcel}
        headers={["Name", "Student ID", "Sports", "Age Category"]}
        fileName="players_data" // Filename for the exported file
      >
        {/* Function as children to ExportAsExcel */}

        {(props) => (
          <button {...props} className="btn btn-primary me-1">
            {" "}
            <i className="uil uil-download-alt me-1"></i> Export as Excel
          </button>
        )}
      </ExportAsExcel>
    );
  };

  // Convert to a number[] array for Table component
const sizePerPageList = sizePerPageOptions.map((option) => option.value);


  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              {/* <div className=" mb-1">
                <div className="text-end d-print-none">
                  <Link
                    to="#"
                    onClick={() => {
                      handleExportPDF();
                    }}
                    className="btn btn-primary me-1"
                  >
                    <i className="uil uil-file-alt me-1" /> Export as PDF
                  </Link>
                </div>
              </div> */}
              <Table
                columns={columns}
                schoolName={schoolName}
                Udise_Code = {Udise_Code}
                data={players ? players : []}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
                tableClass="table-hover"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default List;
